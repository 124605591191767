<template>
    <div class="search-password-wrapper">
        <el-input type="textarea" v-model="desc" ></el-input>
        <div class="toolbar">
            <el-button size="medium" class="copy-btn" @click="copyWriting">复制文案</el-button>
            <div class="clipboard-tip" v-if="isCopyWriting">
                <div class="clipboard-tip-s">
                    <i class="iconfont">&#xe67e;</i><span>复制成功</span>
                </div>
            </div>
        </div>
        <el-input v-model="collect_coupons" :disabled="true"></el-input>
        <div class="toolbar">
            <el-button size="medium" class="copy-btn" @click="copyCollectCoupons">复制领券</el-button>
            <div class="clipboard-tip" v-if="isCollectCoupons">
                <div class="clipboard-tip-s">
                    <i class="iconfont">&#xe67e;</i><span>复制成功</span>
                </div>
            </div>
        </div>
        <el-input v-model="order" :disabled="true"></el-input>
        <div class="toolbar">
            <el-button size="medium" class="copy-btn" @click="copyOrder">复制下单</el-button>
            <div class="clipboard-tip" v-if="isCopyOrder">
                <div class="clipboard-tip-s">
                    <i class="iconfont">&#xe67e;</i><span>复制成功</span>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "SearchPasswordModule",
        data(){
            return{
                desc:'https://m.tb.cn/h.49YTzcr【柳柳老牌螺蛳粉柳州正宗螺丝粉广西特 产螺狮粉袋装包酸辣粉】【立即领券】 4.0￥QY4XcwrEg6D￥',
                isCopyWriting:false,
                collect_coupons:'https://m.tb.cn/h.49YTzcr【柳柳老牌螺蛳粉柳州正宗螺丝粉广西特',
                isCollectCoupons:false,
                order:'https://m.tb.cn/h.49YTzcr【柳柳老牌螺蛳粉柳州正宗螺丝粉广西特',
                isCopyOrder:false,
            }
        },
        methods:{
            copyWriting(){
                this.isCopyWriting = true;
            },
            // 复制领券
            copyCollectCoupons(){
                this.isCollectCoupons = true;
            },
            // 复制下单
            copyOrder(){
                this.isCopyOrder = true;
            },
        }
    }
</script>

<style scoped lang="scss">
    .search-password-wrapper{
        padding-right: 40px;
        ::v-deep .el-textarea__inner{
            height: 150px;
            resize: none;
        }
        .toolbar{
            display: flex;
            align-items: center;
            height: 30px;
            padding: 20px 0;
            .copy-btn{
                background: #FE4800;
                border-radius: 6px;
                color: #fff;
                &:hover,&:focus{
                    background: #ff822e;
                    border-color: #ff822e;
                    color: #FFF;
                }
            }
            .clipboard-tip{

                .clipboard-tip-s{
                    display: flex;
                    align-items: center;
                    color: #91b615;
                    margin-left: 10px;
                }
            }
        }
    }
</style>