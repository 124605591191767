<template>
    <div class="long-link-wrapper">
        <el-input type="textarea" v-model="desc" ></el-input>
        <div class="toolbar">
            <el-button size="medium" class="copy-btn" @click="copyWriting">复制文案</el-button>
            <div class="clipboard-tip" v-if="isCopyWriting">
                <div class="clipboard-tip-s">
                    <i class="iconfont">&#xe67e;</i><span>复制成功</span>
                </div>
            </div>
        </div>
        <el-input v-model="collect_coupons" :disabled="true"></el-input>
        <div class="toolbar">
            <el-button size="medium" class="copy-btn" @click="copyCollectCoupons">复制领券</el-button>
            <div class="clipboard-tip" v-if="isCollectCoupons">
                <div class="clipboard-tip-s">
                    <i class="iconfont">&#xe67e;</i><span>复制成功</span>
                </div>
            </div>
        </div>
        <el-input v-model="order" :disabled="true"></el-input>
        <div class="toolbar">
            <el-button size="medium" class="copy-btn" @click="copyOrder">复制下单</el-button>
            <div class="clipboard-tip" v-if="isCopyOrder">
                <div class="clipboard-tip-s">
                    <i class="iconfont">&#xe67e;</i><span>复制成功</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LongLinkModule",
        data(){
            return{
                desc:'【爆款推荐】柳柳老牌螺蛳粉柳州正宗螺丝粉广西特产螺狮粉袋装包酸辣粉 2小时销量达4535件 原价52.90元，券后价仅17.90元 ----------------- 【立即领券】点击链接即可领券购买：https://uland.taobao.com/coupon/edetail?e=YKeKapHhR 2kNfLV8niU3RxsUty%2FyJZUCIUcOemCte8jHoQe1tkK55bZB4GXhba7v1C%2Bbh2jxocnd4vw zxWPqNXeaeg0zn%2BnQ54AQhCDQ1NVN1ZTN85UD%2F07x%2BtdObC2f5BXKTTHFyTurb9I2 Id%3APUB%401612677480%4056f04291-c1b1-42c8-a4b8-ea21700d1614_623384524133%',
                isCopyWriting:false,
                collect_coupons:'https://ulanGhOmrJX5aYv0D%2BnZJH8o%2FAftuCgKy%2pp_pvid=59590_11.29.161.152_63',
                isCollectCoupons:false,
                order:'https://ulanGhOmrJX5aYv0D%2BnZJH8o%2FAftuCgKy%2pp_pvid=59590_11.29.161.152_63',
                isCopyOrder:false,
            }
        },
        methods:{
            copyWriting(){
                this.isCopyWriting = true;
            },
            // 复制领券
            copyCollectCoupons(){
                this.isCollectCoupons = true;
            },
            // 复制下单
            copyOrder(){
                this.isCopyOrder = true;
            },
        }
    }
</script>

<style scoped lang="scss">
    .long-link-wrapper{
        padding-right: 40px;
        ::v-deep .el-textarea__inner{
            height: 150px;
            resize: none;
        }
        .toolbar{
            display: flex;
            align-items: center;
            height: 30px;
            padding: 20px 0;
            .copy-btn{
                background: #FE4800;
                border-radius: 6px;
                color: #fff;
                &:hover,&:focus{
                    background: #ff822e;
                    border-color: #ff822e;
                    color: #FFF;
                }
            }
            .clipboard-tip{

                .clipboard-tip-s{
                    display: flex;
                    align-items: center;
                    color: #91b615;
                    margin-left: 10px;
                }
            }
        }
    }

</style>