<template>
    <div class="short-linkModule-wrapper">
        <el-input type="textarea" v-model="desc" :readonly="true"  ref="myTextarea"></el-input>
        <div class="toolbar">
            <el-button size="medium" class="copy-btn" v-clipboard:copyhttplist="desc" v-clipboard:success="copyWriting" >复制文案</el-button>
            <div class="clipboard-tip" v-if="isCopyWriting">
                <div class="clipboard-tip-s">
                    <i class="iconfont">&#xe67e;</i><span>复制成功</span>
                </div>
            </div>
        </div>
        <el-input v-if="collect_coupons" v-model="collect_coupons" :readonly="true"  ref="myCollectCoupons"></el-input>
        <div class="toolbar">
            <el-button size="medium" class="copy-btn" v-clipboard:copyhttplist="collect_coupons" v-clipboard:success="copyCollectCoupons">复制领券</el-button>
            <div class="clipboard-tip" v-if="isCollectCoupons">
                <div class="clipboard-tip-s">
                    <i class="iconfont">&#xe67e;</i><span>复制成功</span>
                </div>
            </div>
        </div>
        <el-input v-model="place_order" :readonly="true" ref="myOrder"></el-input>
        <div class="toolbar">
            <el-button size="medium" class="copy-btn"  v-clipboard:copyhttplist="place_order" v-clipboard:success="copyOrder">复制下单</el-button>
            <div class="clipboard-tip" v-if="isCopyOrder">
                <div class="clipboard-tip-s">
                    <i class="iconfont">&#xe67e;</i><span>复制成功</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ShortLinkModule",
        data(){
            return{
                desc:'【爆款推荐】黑色吸光布摄影黑布背景布拍照视频道具人像证件照加厚纯色静物植绒布直播拍摄道具2小时销量达5934件-----------------【立即领券】点击链接即可领券购买：https://s.click.taobao.com/nOEjAru 【立即下单】点击链接立即下单：https://s.click.taobao.com/IJBiAru ',
                isCopyWriting:false,
                collect_coupons:'https://s.click.taobao.com/350SUru',
                isCollectCoupons:false,
                place_order:'https://s.click.taobao.com/YOyBPru',
                isCopyOrder:false,
            }
        },
        methods:{
            copyWriting(){
                this.isCopyWriting = true;
                this.$refs.myTextarea.select();
                setTimeout(()=>{
                    console.log(11111)
                    this.isCopyWriting = false;
                },1000)
            },
            // 复制领券
            copyCollectCoupons(){
                this.isCollectCoupons = true;
                this.$refs.myCollectCoupons.select();
                console.log(this.$refs.myCollectCoupons,'this.$refs.myCollectCoupons')
                setTimeout(()=>{
                    this.isCollectCoupons = false;
                },1000)

            },
            // 复制下单
            copyOrder(){
                this.isCopyOrder = true;
                this.$refs.myOrder.select();
                setTimeout(()=>{
                    console.log(11111)
                    this.isCopyOrder = false;
                },1000)
            },

        }
    }
</script>

<style scoped lang="scss">
    .short-linkModule-wrapper{
        padding-right: 40px;
        ::v-deep .el-textarea__inner{
            height: 150px;
            resize: none;
        }
        ::v-deep .el-input__inner{
            background: #F1F0FE;
        }
        .toolbar{
            display: flex;
            align-items: center;
            height: 30px;
            padding: 20px 0;
            .copy-btn{
                background: #FE4800;
                border-radius: 6px;
                color: #fff;
                &:hover,&:focus{
                    background: #ff822e;
                    border-color: #ff822e;
                    color: #FFF;
                }
            }
            .clipboard-tip{

                .clipboard-tip-s{
                    display: flex;
                    align-items: center;
                    color: #91b615;
                    margin-left: 10px;
                }
            }
        }
    }

</style>